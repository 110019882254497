import React from 'react'
import PropTypes from 'prop-types'

const FallbackImage = ({ path, title, openModal }) => {
  return (
    <>
      {path ? (
        <img src={path} alt={title} loading="lazy" onClick={() => openModal(path)} />
      ) : (
        <img
          src="/images/fallback.png"
          loading="lazy"
          height="257px"
          alt={title}
          onClick={() => openModal('/images/fallback.png')}
        />
      )}
    </>
  )
}

FallbackImage.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  openModal: PropTypes.func,
}

export default FallbackImage
